.badge {
  display: inline-flex;
  position: relative;
  justify-content: center;
  align-content: center;
  align-items: center;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  outline: none;
  overflow: hidden;
  font-family: "CircularStd-Bold", serif;
  transition: background-color .25s ease-out, color .25s ease-out;
  min-width: min-content;

  .badge-icon {
    display: flex;
  }

  .badge-content {
    display: flex;
    vertical-align: middle;
    justify-content: center;
    align-content: center;
    align-items: center;
    text-align: center;
  }

  /* Size */
  &-#{'sm'} {
    padding: 2px 6px 0;
    border-radius: 4px;
    font-size: 10px;
    line-height: 18px;
    .badge-icon {
      padding-left: 4px;
    }
    .badge-content {
      padding: 0;
      height: 18px;
      line-height: 18px;
      font-family: inherit;
    }
  }
  &-#{'md'} {
    border-radius: 4px;
    padding: 4px 8px;
    height: 24px;
    max-height: 24px;
    .badge-icon {
      padding-right: 3px;
    }
    .badge-content {
      font-size: 12px;
      line-height: 16px;
      font-family: inherit;
    }
  }
  &-#{'lg'} {
    font-size: 12px;
    border-radius: 4px;
    padding: 2px 4px 0;
    .badge-icon {
      padding-left: 4px;
    }
    .badge-content {
      padding: 0 4px;
      height: 22px;
      line-height: 22px;
      font-family: inherit;
    }
  }

  /* Variant */
  &-#{'draft'} {
    fill: #f73D43;
    color: #f73D43;
    background-color: #feecec;
    &:hover {
      fill: #f73D43;
      color: #f73D43;
      background-color: #feecec;
    }
    .badge-icon {
      svg {
        g {
          fill: #f73D43;
        }
      }
    }
  }

  &-#{'rejected'} {
    fill: #f73D43;
    color: #f73D43;
    background-color: #feecec;
    &:hover {
      fill: #f73D43;
      color: #f73D43;
      background-color: #feecec;
    }
    .badge-icon {
      svg {
        g {
          fill: #f73D43;
        }
      }
    }
  }

  &-#{'started'} {
    fill: #00D000;
    color: #00D000;
    background-color: #e6fae6;
    &:hover {
      fill: #00D000;
      color: #00D000;
      background-color: #e6fae6;
    }
    .badge-icon {
      svg {
        g {
          fill: #00D000;
        }
      }
    }
  }

  &-#{'confirmed'} {
    fill: #00D000;
    color: #00D000;
    background-color: #e6fae6;
    &:hover {
      fill: #00D000;
      color: #00D000;
      background-color: #e6fae6;
    }
    .badge-icon {
      svg {
        g {
          fill: #00D000;
        }
      }
    }
  }

  &-#{'pending'} {
    fill: #FFBB00;
    color: #FFBB00;
    background-color: #fff8e6;
    &:hover {
      fill: #FFBB00;
      color: #FFBB00;
      background-color: #fff8e6;
    }
    .badge-icon {
      svg {
        g {
          fill: #FFBB00;
        }
      }
    }
  }

  &-#{'cohost'} {
    fill: rgba(59, 165, 255, 1);
    color: rgba(59, 165, 255, 1);
    background-color: rgba(59, 165, 255, 0.05);
    &:hover {
      fill: rgba(59, 165, 255, 0.8);
      color: rgba(59, 165, 255, 0.8);
      background-color: rgba(59, 165, 255, 0.05);
    }
    .badge-icon {
      svg {
        g {
          fill: rgba(59, 165, 255, 1);
        }
      }
    }
  }

  &-#{'virtual'} {
    fill: #00ABBA;
    color: #00ABBA;
    background-color: #e6f7f8;
    &:hover {
      fill: #00ABBA;
      color: #00ABBA;
      background-color: #e6f7f8;
    }
    .badge-icon {
      svg {
        g {
          fill: #00ABBA;
        }
      }
    }
  }

  &-#{'invitepending'} {
    fill: #f6921e;
    color: #f6921e;
    background-color: #fef4e9;
    &:hover {
      fill: #f6921e;
      color: #f6921e;
      background-color: #fef4e9;
    }
    .badge-icon {
      svg {
        g {
          fill: #f6921e;
        }
      }
    }
  }
  &-#{'going'} {
    fill: #f6921e;
    color: #f6921e;
    background-color: #fef4e9;
    &:hover {
      fill: #f6921e;
      color: #f6921e;
      background-color: #fef4e9;
    }
    .badge-icon {
      svg {
        g {
          fill: #f6921e;
        }
      }
    }
  }

  &-#{'invitesent'} {
    fill: #3BA5FF;
    color: #3BA5FF;
    background-color: #ecf4ff;
    &:hover {
      fill: #3BA5FF;
      color: #3BA5FF;
      background-color: #ecf4ff;
    }
    .badge-icon {
      svg {
        g {
          fill: #3BA5FF;
        }
      }
    }
  }

  &-#{'external'} {
    fill: #746CF7;
    color: #746CF7;
    background-color: #F0F0FE;
    &:hover {
      fill: #746CF7;
      color: #746CF7;
      background-color: #F0F0FE;
    }
    .badge-icon {
      svg {
        g {
          fill: #F0F0FE;
        }
      }
    }
  }

  &-#{'internalstatus'} {
    fill: #00D000;
    color: #00D000;
    background-color: #00D0001f;
    &:hover {
      fill: #00D000;
      color: #00D000;
      background-color: #00D0001f;
    }
    .badge-icon {
      svg {
        g {
          fill: #00D000;
        }
      }
    }
  }

  &-#{'manual'} {
    fill: #FFAD0D;
    color: #FFAD0D;
    background-color: #FFAD0D1f;
    &:hover {
      fill: #FFAD0D;
      color: #FFAD0D;
      background-color: #FFAD0D1f;
    }
    .badge-icon {
      svg {
        g {
          fill: #FFAD0D;
        }
      }
    }
  }

  &-#{'beta'} {
    fill: #fff;
    color: #fff;
    background-color: #FF8A65;
    &:hover {
      fill: #fff;
      color: #fff;
      background-color: #FF8A65;
    }
    .badge-icon {
      svg {
        g {
          fill: #fff;
        }
      }
    }
  }

  &-#{'noshow'} {
    fill: #F73D34;
    color: #F73D34;
    background-color: #FEEBEA;
    &:hover {
      fill: #F73D34;
      color: #F73D34;
      background-color: #FEEBEA;
    }
    .badge-icon {
      svg {
        g {
          fill: #F0F0FE;
        }
      }
    }
  }

  &-#{'skipped'} {
    fill: #804446;
    color: #804446;
    background-color: #f2eded;
    &:hover {
      fill: #804446;
      color: #804446;
      background-color: #f2eded;
    }
    .badge-icon {
      svg {
        g {
          fill: #F0F0FE;
        }
      }
    }
  }

  &-#{'connected'} {
    fill: #3BA5FF;
    color: #3BA5FF;
    background-color: #ecf4ff;
    &:hover {
      fill: #3BA5FF;
      color: #3BA5FF;
      background-color: #ecf4ff;
    }
    .badge-icon {
      svg {
        g {
          fill: #3BA5FF;
        }
      }
    }
  }

  &-#{'visited'} {
    fill: #00ABBA;
    color: #00ABBA;
    background-color: #e6f7f8;
    &:hover {
      fill: #00ABBA;
      color: #00ABBA;
      background-color: #e6f7f8;
    }
    .badge-icon {
      svg {
        g {
          fill: #F0F0FE;
        }
      }
    }
  }

  &-#{'declined'} {
    fill: #F73D34;
    color: #F73D34;
    background-color: #f3f4f6;
    &:hover {
      fill: #F73D34;
      color: #F73D34;
      background-color: #f3f4f6;
    }
    .badge-icon {
      svg {
        g {
          fill: #F0F0FE;
        }
      }
    }
  }
  &-#{'dismissed'} {
    fill: #A6AEBC;
    color: #A6AEBC;
    background-color: #f3f4f6;
    &:hover {
      fill: #A6AEBC;
      color: #A6AEBC;
      background-color: #f3f4f6;
    }
    .badge-icon {
      svg {
        g {
          fill: #F0F0FE;
        }
      }
    }
  }

  &-#{'dismiss'} {
    fill: #53627C;
    color: #53627C;
    background-color: #eeeff2;
    &:hover {
      fill: #53627C;
      color: #53627C;
      background-color: #eeeff2;
    }
    .badge-icon {
      svg {
        g {
          fill: #F0F0FE;
        }
      }
    }
  }

  &-#{'network-owner'} {
    fill: #3686FF;
    color: #3686FF;
    background-color: rgba(54, 134, 255, 0.2);
    &:hover {
      fill: #3686FF;
      color: #3686FF;
      background-color: rgba(54, 134, 255, 0.15);
    }
    .badge-icon {
      svg {
        g {
          fill: #3686FF;
        }
      }
    }
  }

  &-#{'network-your-organization'} {
    fill: #F6921E;
    color: #F6921E;
    background-color: rgba(246, 146, 30, 0.2);
    &:hover {
      fill: #F6921E;
      color: #F6921E;
      background-color: rgba(246, 146, 30, 0.2);
    }
    .badge-icon {
      svg {
        g {
          fill: #F6921E;
        }
      }
    }
  }

  &-#{'network-declined'} {
    fill: #F73D34;
    color: #F73D34;
    background-color: rgba(247, 61, 52, 0.2);
    &:hover {
      fill: #F73D34;
      color: #F73D34;
      background-color: rgba(247, 61, 52, 0.2);
    }
    .badge-icon {
      svg {
        g {
          fill: #F73D34;
        }
      }
    }
  }

  &-#{'network-pending'} {
    fill: #FFBB00;
    color: #FFBB00;
    background-color: rgba(255, 187, 0, 0.2);
    &:hover {
      fill: #FFBB00;
      color: #FFBB00;
      background-color: rgba(255, 187, 0, 0.2);
    }
    .badge-icon {
      svg {
        g {
          fill: #FFBB00;
        }
      }
    }
  }

  &-#{'registry-funded'} {
    border-radius: 12px;
    fill: #FFFFFF;
    color: #FFFFFF;
    background-color: #28C177;
    &:hover {
      fill: #FFFFFF;
      color: #FFFFFF;
      background-color: #28C177;
    }
    .badge-icon {
      padding-right: 5px;
      svg {
        g {
          fill: #FFFFFF;
        }
      }
    }
    .badge-content {
      font-family: "CircularStd-Medium", serif;
    }
  }

  &-#{'registry-gifted'} {
    border-radius: 12px;
    fill: #FFFFFF;
    color: #FFFFFF;
    background-color: #3BA5FF;
    &:hover {
      fill: #FFFFFF;
      color: #FFFFFF;
      background-color: #3BA5FF;
    }
    .badge-icon {
      padding-right: 5px;
      svg {
        g {
          fill: #FFFFFF;
        }
      }
    }
    .badge-content {
      font-family: "CircularStd-Medium", serif;
    }
  }

  &-#{'registry-high'} {
    border-radius: 12px;
    fill: #262626;
    color: #262626;
    background-color: #F9F9F9;
    &:hover {
      fill: #262626;
      color: #262626;
      background-color: #F9F9F9;
    }
    .badge-icon {
      svg {
        g {
          fill: #3BA5FF;
        }
      }
    }
    .badge-content {
      font-family: "CircularStd-Medium", serif;
    }
  }

  &-#{'registry-medium'} {
    border-radius: 12px;
    fill: #262626;
    color: #262626;
    background-color: #F9F9F9;
    &:hover {
      fill: #262626;
      color: #262626;
      background-color: #F9F9F9;
    }
    .badge-icon {
      svg {
        g {
          fill: #3BA5FF;
        }
      }
    }
    .badge-content {
      font-family: "CircularStd-Medium", serif;
    }
  }

  &-#{'registry-standard'} {
    border-radius: 12px;
    fill: #262626;
    color: #262626;
    background-color: #F9F9F9;
    &:hover {
      fill: #262626;
      color: #262626;
      background-color: #F9F9F9;
    }
    .badge-icon {
      svg {
        g {
          fill: #3BA5FF;
        }
      }
    }
    .badge-content {
      font-family: "CircularStd-Medium", serif;
    }
  }

}
