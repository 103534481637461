.react-tel-input {
  .form-control {
    font-size: 14px;
    background: #FFFFFF;
    border: 1px solid #e1e4e8;
    border-radius: 4px;
    width: 100%;
    outline: none;
    padding: 14px 14px 10px 70px;
    transition: box-shadow ease .25s,border-color ease .25s;
    &:hover {
      border-color: #a6aebc;
    }
    &:focus {
      border-color: #a6aebc;
      outline: none;
      box-shadow: none;
    }
  }
  .special-label {
    position: absolute;
    z-index: 1;
    top: -7px;
    left: 16px;
    display: block;
    background: white;
    padding: 0 5px;
    font-size: 12px;
    white-space: nowrap;
    color: #a6aebc;
    font-family: 'CircularStd-Book', sans-serif;
  }
  .selected-flag {
    outline: none;
    position: relative;
    width: 52px;
    height: 100%;
    padding: 0 0 0 20px;
    border-radius: 3px 0 0 3px;
    .flag {
      margin-top: -9px;
    }
  }
}