.pagination{
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  li {
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
    width: 36px;
    background-color: #ffffff;
    border-radius: 10px;
    border: 1px solid #F2F3F5;
    margin: 0 10px 10px 0;
    outline: none;
    cursor: pointer;
    g {
      fill: #464F59;
    }
    &:hover {
      background-color: #ffffff;
      border: 1px solid #A6AEBC;
      a {
        color: #464F59;
      }
      g {
        fill: #464F59;
      }
    }
    a {
      color: #464F59;
      font-family: "CircularStd-Medium", serif;
      font-size: 14px;
      font-weight: normal;
      line-height: 18px;
      display: flex;
      width: 36px;
      height: 36px;
      text-align: center;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
    }
  }
  .pagination-item-active{
    background-color: #ffffff;
    border: 1px solid #A6AEBC;
    a{
      color: #464F59;
    }
  }
  .pagination-disabled {
    display: none;
  }
}
